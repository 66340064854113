import { GtmCode } from '@hubcms/domain-tracking';

export { pt as dateFnsLocale } from 'date-fns/locale/pt';
export const locale = 'pt-LU';
export const timeZone = 'Europe/Luxembourg';
export const archiveTitle = 'Arquivo';
export const gtmCodes: GtmCode[] = [{ code: 'GTM-MJWKBXZ', isParent: true }, { code: 'GTM-T52R88F' }];
export const brandCode = 'co';
export const adunitBrandCode = 'contacto';
export const b2bUrlBrandCode = 'contacto';
export const b2bDMPMetaTagBrandCode = 'stp';
export const footerLogo = 'variations.brand-name-white';
export const headerDateFormat = 'EEEE, d MMMM yyyy';
export const isHeroBlockMailIconInverted = true;
export const isSubscriptionMenuEnabled = false;
export const authorPrefix = 'autores/';
export const searchPrefix = 'pesquisa/';
export const tagsPrefix = 'tag/';
export const tagsb2bPrefix = 'conteudo-promocional/';
export const weatherSectionPage = '/tempo/';
export const cimIdentifier = 'AfHgkirDYxC4IxiUr_mNN4XD.Bhdf0vgsM78Ijocz5f.G7';
export const cimLanguage = 'OTHER';
export const tickarooClientId = '64772af30bfac400a065fb8b';
export const domain = 'contacto.lu';
export const heroBlockIntroProps = {
  size: 'lg',
  weight: 'strong',
};
export const enablePersonalizedArticleList = true;
export const enableGrapeshot = true;
export const articleLayoutVersion = 'v2'; // articleV1 param
export const isAppTopicsMenuEnabled = false;
