import cx from 'classnames';
import { CSSProperties, useEffect } from 'react';

import { useElementOffset } from '@hubcms/utils-browser';

import HeaderMainNavigation from './HeaderMainNavigation';
import HeaderMoreMenu from './HeaderMoreMenu';
import styles from './header-navigation.module.scss';
import { HeaderNavigationProps } from './types';

function HeaderNavigation({ isMobileNavigationOpen, className, children, ...props }: HeaderNavigationProps) {
  const { elementRef, offset, calculateOffset } = useElementOffset();

  useEffect(() => {
    if (isMobileNavigationOpen) {
      calculateOffset();
    }
  }, [calculateOffset, isMobileNavigationOpen]);

  return (
    <nav
      className={cx(styles.headerNavigation, { [styles.open]: isMobileNavigationOpen }, className, 'no-print')}
      ref={elementRef}
      style={{ '--nav-element-offset': `${offset}px` } as CSSProperties}
      {...props}
    >
      {children}
    </nav>
  );
}

HeaderNavigation.Main = HeaderMainNavigation;
HeaderNavigation.MoreMenu = HeaderMoreMenu;

export default HeaderNavigation;
