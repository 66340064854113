import { MenuItemFragment, SubmenuItemsFragment } from '@hubcms/domain-cook';
import { getSectionPreference } from './getSectionPreference';

const mapMySectionToSubNav = (item: SubmenuItemsFragment): SubmenuItemsFragment & { clientHref?: string } => {
  if (item.__typename === 'SectionMenuItem') {
    return {
      ...item,
      clientHref: getSectionPreference(item.href),
    };
  }
  return item;
};

export const mapMySectionToNav = (
  item: MenuItemFragment,
): MenuItemFragment & { item: { subcats?: Array<ReturnType<typeof mapMySectionToSubNav>>; clientHref?: string } } => {
  if (item.item.__typename === 'SectionMenuItem') {
    const subcatsItem = item.item.subcats?.length ? { subcats: item.item.subcats.map(mapMySectionToSubNav) } : null;
    return {
      ...item,
      item: {
        ...item.item,
        ...subcatsItem,
        clientHref: getSectionPreference(item.item.href),
      },
    };
  }
  return item;
};
