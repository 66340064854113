import { Button, Dropdown, DropdownItem } from '@mediahuis/chameleon-react';
import { useRef } from 'react';

import { createNavigationItemClickHandler } from '../NavigationItem';
import { UserMenuAuthenticatedButton, UserMenuUnauthenticatedButton } from '../UserMenuButton';
import { UserMenuProps } from './types';

import UserMenuItem from './UserMenuItem';

import styles from './user-menu.module.scss';

function UserMenu({
  isAuthenticated,
  user = { givenName: '', familyName: '' },
  userMenuItems,
  loginLabel,
  logoutLabel,
  loginAction,
  logoutAction,
  style,
}: UserMenuProps) {
  const buttonRef = useRef<typeof Button>(null);
  const dropdownRef = useRef<typeof Dropdown>(null);
  const toggleUserMenu = () => dropdownRef.current?.toggle();
  const handleUserMenuAuthenticatedButtonClick = createNavigationItemClickHandler(toggleUserMenu);
  const handleUserMenuUnauthenticatedButtonClick = createNavigationItemClickHandler(loginAction);

  const isAuthenticatedUser = isAuthenticated && user !== null;

  return (
    <>
      {isAuthenticatedUser ? (
        <UserMenuAuthenticatedButton ref={buttonRef} user={user} onClick={handleUserMenuAuthenticatedButtonClick} />
      ) : (
        <UserMenuUnauthenticatedButton ref={buttonRef} label={loginLabel} onClick={handleUserMenuUnauthenticatedButtonClick} />
      )}

      <Dropdown
        className={styles.dropDown}
        style={style}
        ref={dropdownRef}
        anchorRef={buttonRef}
        placement="bottom-end"
        data-testid="user-menu"
      >
        {userMenuItems.map(userMenuItem => (
          <UserMenuItem item={userMenuItem} key={userMenuItem.uniqueId} />
        ))}
        <DropdownItem title={logoutLabel} onClick={logoutAction} />
      </Dropdown>
    </>
  );
}

export default UserMenu;
