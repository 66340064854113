import dynamic from 'next/dynamic';
import { ComponentType, PropsWithChildren } from 'react';

import { AuthMethod } from '@hubcms/domain-auth';

const Auth0Provider = dynamic(() => import('./auth0/Auth0Provider').then(m => m.Auth0Provider));
const OAuthProvider = dynamic(() => import('./oAuth/OAuthProvider').then(m => m.OAuthProvider));
const CiamAuth0Provider = dynamic(() => import('./ciamAuth0/CiamAuth0Provider').then(m => m.CiamAuth0Provider));
const NoAuthProvider = dynamic(() => import('./noAuth/NoAuthProvider').then(m => m.NoAuthProvider));

export const providers: Record<AuthMethod, ComponentType<PropsWithChildren>> = {
  auth0: Auth0Provider,
  oauth: OAuthProvider,
  ciamAuth0: CiamAuth0Provider,
  none: NoAuthProvider,
};
