import { useContext, PropsWithChildren, useMemo } from 'react';

import type { TrackingData } from '@hubcms/domain-tracking';

import { TrackingDataContext } from './TrackingDataContext';

type TrackingDataProviderProps = PropsWithChildren<{
  value: TrackingData;
}>;

export function TrackingDataProvider({ value, children }: TrackingDataProviderProps) {
  const previousValue = useContext(TrackingDataContext);
  const conjoinedValue = useMemo(() => {
    return { ...previousValue, ...value };
  }, [previousValue, value]);

  return <TrackingDataContext.Provider value={conjoinedValue}>{children}</TrackingDataContext.Provider>;
}
