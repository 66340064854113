import { FC } from 'react';
import { Caption } from '@mediahuis/chameleon-react';

import type { EPaperLinkHref, EPaperLinkHrefTarget, EPaperLinkImage, EPaperLinkLabel } from '@hubcms/domain-navigation';

import styles from './e-paper-link.module.scss';
import { NavigationItem } from '../NavigationItem';

type EPaperLinkProps = {
  href?: EPaperLinkHref;
  image?: EPaperLinkImage;
  label?: EPaperLinkLabel;
  target?: EPaperLinkHrefTarget;
};

const EPaperLink: FC<EPaperLinkProps> = props => {
  if (!props.label) {
    return null;
  }

  const Component = props.href ? NavigationItem : 'div';
  const componentProps = props.href
    ? { href: props.href, target: props.target, rel: 'noreferrer', trackingName: props.label, trackingBlock: 'Services left' }
    : {};
  return (
    <Component {...componentProps} className={styles.ePaperLink} data-testid="e-paper">
      {props.image ? <img alt="" height={props.image.height} src={props.image.src} width={props.image.width} /> : null}
      <Caption className={styles.ePaperLinkLabel}>{props.label}</Caption>
    </Component>
  );
};

export default EPaperLink;
