import { createContext } from 'react';
import type { IdToken } from '@auth0/auth0-react';

import { ApiIdentity } from '@hubcms/domain-auth';

type IAuthContext = {
  user: ApiIdentity['user'] | null;
  isAuthenticated: boolean;
  login: (returnUrl?: string) => void;
  logout: (returnUrl?: string) => void;
  isLoading: boolean;
  getIdTokenClaims?: () => Promise<IdToken | undefined>;
};

export const AuthContext = createContext<IAuthContext>({
  user: null,
  isAuthenticated: false,
  login: () => {
    throw new Error('Auth method set to none.');
  },
  logout: () => {
    throw new Error('Auth method set to none.');
  },
  isLoading: false,
});
