import { Icon } from '@mediahuis/chameleon-react';
import { ChevronDown, ChevronUp, Search } from '@mediahuis/chameleon-theme-wl/icons';
import cx from 'classnames';
import { MouseEventHandler } from 'react';

import { NavColumn, NavItem } from '@hubcms/domain-navigation';
import { Container } from '@hubcms/ui-container';

import { NavigationItem } from '../../NavigationItem';

import styles from './header-main-navigation.module.scss';

type HeaderMainSubNavigationProps = {
  navItems: NavItem[];
  className?: string;
};

function HeaderMainSubNavigation({ navItems, className }: HeaderMainSubNavigationProps) {
  return (
    <Container className={cx(styles.mainSubContainer, className)}>
      {navItems.map(({ uniqueId, text, url, target, isActive }) => (
        <NavigationItem
          trackingBlock="Main navigation"
          trackingName={text}
          key={uniqueId}
          href={url}
          target={target}
          isActive={isActive}
        >
          {text}
        </NavigationItem>
      ))}
    </Container>
  );
}

type HeaderMainNavigationProps = {
  columns: NavColumn[];
  moreButtonLabel: string;
  searchButtonLabel: string;
  onSearchClicked: () => void;
  onMoreClicked: () => void;
  isMoreMenuOpen: boolean;
  hasMoreButton: boolean;
};

function HeaderMainNavigation({
  columns,
  moreButtonLabel,
  searchButtonLabel,
  onSearchClicked,
  onMoreClicked,
  isMoreMenuOpen,
  hasMoreButton,
}: HeaderMainNavigationProps) {
  const handleSearchClicked: MouseEventHandler = e => {
    onSearchClicked();
    e.stopPropagation();
  };

  return (
    <Container className={styles.mainContainer} data-testid="header-main-navigation">
      <div className={styles.mainInnerContainer}>
        <div className={cx(styles.mainContent, styles.mainSideContentStart)}></div>
        <div className={styles.mainContent}>
          {columns.map(({ heading }) => (
            <NavigationItem
              trackingBlock="Main navigation"
              trackingName={heading.text}
              key={heading.uniqueId}
              href={heading.url}
              clientHref={heading.clientUrl}
              serverHref={heading.serverUrl}
              target={heading.target}
              isActive={heading.isActive}
            >
              {heading.text}
            </NavigationItem>
          ))}
          {hasMoreButton && (
            <NavigationItem className={styles.moreButton} onClick={onMoreClicked} data-testid="header-main-navigation-more">
              {moreButtonLabel}
              <Icon as={isMoreMenuOpen ? ChevronUp : ChevronDown} size="sm" />
            </NavigationItem>
          )}
        </div>
        <div className={cx(styles.mainContent, styles.mainSideContentEnd)}>
          <NavigationItem onMouseUp={handleSearchClicked} className={styles.searchButton} data-testid="search">
            <Icon as={Search} size="sm" />
            {searchButtonLabel}
          </NavigationItem>
        </div>
      </div>
    </Container>
  );
}

HeaderMainNavigation.SubNavigation = HeaderMainSubNavigation;

export default HeaderMainNavigation;
