import type { PropsWithChildren } from 'react';

import { useState } from 'react';

import { NavigationContext } from './context';

function NavigationProvider({ children }: PropsWithChildren) {
  const [isAsideMenuOpen, setIsAsideMenuOpen] = useState(false);

  const toggleIsAsideMenuOpen = () => setIsAsideMenuOpen(prevValue => !prevValue);

  return <NavigationContext.Provider value={{ isAsideMenuOpen, toggleIsAsideMenuOpen }}>{children}</NavigationContext.Provider>;
}

export default NavigationProvider;
