import { FC } from 'react';
import cx from 'classnames';

import { NavColumn, NavItem } from '@hubcms/domain-navigation';
import { Container } from '@hubcms/ui-container';

import { NavigationItem } from '../../NavigationItem';

import styles from './header-more-menu.module.scss';

type HeaderMoreColumnProps = {
  heading: NavItem;
  subItems?: NavItem[];
  className?: string;
};

const HeaderMoreColumn: FC<HeaderMoreColumnProps> = ({ heading, subItems, className }) => {
  return (
    <div className={cx(styles.column, className)}>
      <NavigationItem
        trackingBlock="Main navigation more"
        trackingName={heading.text}
        href={heading.url}
        target={heading.target}
        isActive={heading.isActive}
        className={styles.columnHeader}
      >
        {heading.text}
      </NavigationItem>
      {subItems?.map(({ uniqueId, text, url, target, isActive }) => (
        <NavigationItem
          trackingBlock="Main navigation more"
          trackingName={text}
          key={uniqueId}
          href={url}
          target={target}
          isActive={isActive}
          className={styles.columnItem}
        >
          {text}
        </NavigationItem>
      ))}
    </div>
  );
};

type HeaderMoreMenuProps = {
  isMoreMenuVisible: boolean;
  columns: NavColumn[];
};

function HeaderMoreMenu({ isMoreMenuVisible, columns }: HeaderMoreMenuProps) {
  return (
    <>
      {isMoreMenuVisible && (
        <Container className={styles.moreMenu} data-testid="header-main-navigation-more-menu">
          <div className={styles.content}>
            {columns.map(({ heading, subItems }) => (
              <HeaderMoreColumn key={heading.uniqueId} heading={heading} subItems={subItems} />
            ))}
          </div>
        </Container>
      )}
      {columns.map(({ heading, subItems }) => (
        <HeaderMoreColumn key={heading.uniqueId} heading={heading} subItems={subItems} className={styles.mobileColumn} />
      ))}
    </>
  );
}

export default HeaderMoreMenu;
