import { SubscriptionShopUtmData } from './create-subscription-shop-utm-params';

export function mapSubscriptionShopUtmParams(
  data: Record<string, string | number | boolean | null | undefined>,
): SubscriptionShopUtmData {
  return {
    // eslint-disable-next-line camelcase
    utm_artid: data.utm_artid ? data.utm_artid.toString() : undefined,
    // eslint-disable-next-line camelcase
    utm_internal: `menu-www-${data.utm_internal}`,
  };
}
