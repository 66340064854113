import { ContextType, PropsWithChildren } from 'react';

import { EnvContext } from './EnvContext';

type EnvProviderProps = PropsWithChildren<{
  value: ContextType<typeof EnvContext>;
}>;

export function EnvProvider({ children, value }: EnvProviderProps) {
  return <EnvContext.Provider value={value}>{children}</EnvContext.Provider>;
}
