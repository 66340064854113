import { MenuEvent } from '@hubcms/domain-tracking';

export type MenuData = Omit<MenuEvent, 'event' | 'eventaction' | 'eventcategory' | 'wallid'>;

export function createMenuEvent(data: MenuData): MenuEvent {
  const isLoginLabel = data.eventlabel === 'login';
  const commonProps = {
    event: 'page_event',
    eventaction: 'click',
    eventcategory: 'menu',
    wallid: null,
  } as const;
  if (isLoginLabel) {
    return {
      ...commonProps,
      eventlabel: 'login',
      itemdescription: 'btnheader',
      method: 'menu-www-btnheader',
      ga_event_name: 'login_click', // eslint-disable-line camelcase
    };
  }
  return {
    ...commonProps,
    eventlabel: 'logout',
  };
}
