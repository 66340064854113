import { useEffect, useState } from 'react';

import { getDidomiExternalConsentAsync } from '@hubcms/utils-didomi';

import { isStringInHostname } from '../isStringInHostname';

export function useExternalConsentUrl(url?: string): string | null {
  const [externalUrl, setExternalUrl] = useState(url ?? null);

  const validatedUrl = validateUrl(url ? url : '');

  const isMhDomain = validatedUrl ? isStringInHostname(validatedUrl, '.mediahuis.com') : false;

  useEffect(() => {
    if (validatedUrl && isMhDomain && !hasMhExternalConsent(validatedUrl)) {
      // eslint-disable-next-line promise/catch-or-return, promise/always-return
      appendMhExternalConsent(validatedUrl).then(urlWithConsent => {
        setExternalUrl(urlWithConsent.toString());
      });
    } else {
      setExternalUrl(null);
    }
  }, [isMhDomain, validatedUrl]);

  return externalUrl;
}

const externalConsentKey = 'didomiConfig.user.externalConsent.value';
const vendorIds = ['getsitecon-9CqzG7Z6', 'googleana-n6UMhJ7e', 'hotjar-ynF8maUR', 'mediahuis-UhN32Fqq'];

async function appendMhExternalConsent(url: URL): Promise<URL> {
  try {
    const didomiExternalConsent = await getDidomiExternalConsentAsync(vendorIds);

    url.searchParams.append(externalConsentKey, encodeURIComponent(JSON.stringify(didomiExternalConsent)));

    return url;
  } catch {
    return url;
  }
}

function hasMhExternalConsent(url: URL): boolean {
  return url.searchParams.has(externalConsentKey);
}

function validateUrl(url: string): URL | null {
  try {
    return new URL(url);
  } catch {
    return null;
  }
}
