import { FC, useMemo } from 'react';
import { Button } from '@mediahuis/chameleon-react';
import type { NavItem } from '@hubcms/domain-navigation';
import { useZephrData } from '@hubcms/data-access-zephr';
import { addSearchParams, createUrl } from '@hubcms/utils-url';
import { createTrackingClickHandler, useTrackingData } from '@hubcms/data-access-tracking';
import {
  createClickSubscriptionShopEvent,
  createSubscriptionShopUtmParams,
  mapSubscriptionShopUtmParams,
} from '@hubcms/utils-tracking';
import { subscriptionButtonAppearance } from '@hubcms/brand';
import { getThemeDataAttributes } from '@hubcms/utils-theme';

type NavItemWithUrlAndText = NavItem & { url: string; text: string };

const SubscriptionButton: FC<{ subscriptionMenuItems: NavItem[] }> = ({ subscriptionMenuItems }) => {
  const { subscriber } = useZephrData();
  const trackingData = useTrackingData();

  const isSubscriber = subscriber && Object.keys(subscriber).length > 0;
  const shouldRenderSubscriptionButton = !isSubscriber || subscriber.allowedProducts.length === 0;

  const onClickHandler = useMemo(() => {
    if (typeof window !== 'undefined') {
      const clickSubscriptionShopEvent = createClickSubscriptionShopEvent({ itemdescription: 'btnheader' });
      return createTrackingClickHandler(clickSubscriptionShopEvent);
    }
    return undefined;
  }, []);

  if (!shouldRenderSubscriptionButton) return null;

  return (
    <>
      {subscriptionMenuItems
        .filter(
          (navItem): navItem is NavItemWithUrlAndText => typeof navItem.url === 'string' && typeof navItem.text === 'string',
        )
        .map(({ url, text, target = '_blank', uniqueId = '' }) => {
          const buttonUrl = url && createUrl(url);
          const aboshopUtmData = mapSubscriptionShopUtmParams({
            // eslint-disable-next-line camelcase
            utm_artid: trackingData.pageid,
            // eslint-disable-next-line camelcase
            utm_internal: 'btnheader',
          });
          const aboshopUtmParams = createSubscriptionShopUtmParams(aboshopUtmData);

          buttonUrl.search = addSearchParams(buttonUrl.search.toString(), aboshopUtmParams);

          return (
            <Button
              as="a"
              key={uniqueId}
              onClick={onClickHandler}
              href={buttonUrl.toString()}
              target={target}
              size="sm"
              appearance={subscriptionButtonAppearance}
              data-testid="subscription-button"
              {...getThemeDataAttributes('premium')}
            >
              {text}
            </Button>
          );
        })}
    </>
  );
};

export default SubscriptionButton;
