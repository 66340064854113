import Cookies from 'js-cookie';
import { generateUID } from '@hubcms/utils-tracking';

const GLITR_COOKIE_ID_COOKIE_NAME = '_mhtc_cId';
const GLITR_SESSION_ID_COOKIE_NAME = '_mhtc_sId';

const getSetGlitrId = (glitrIdCookieName: string) => {
  const cookieValue = Cookies.get(glitrIdCookieName);

  if (cookieValue) {
    return cookieValue;
  }

  const generatedUID = generateUID();
  Cookies.set(glitrIdCookieName, generatedUID);

  return generatedUID;
};

export const getSetGlitrCookieId = () => {
  return getSetGlitrId(GLITR_COOKIE_ID_COOKIE_NAME);
};

export const getSetGlitrSessionId = () => {
  return getSetGlitrId(GLITR_SESSION_ID_COOKIE_NAME);
};
