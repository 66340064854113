import { useCallback, useState } from 'react';

function useOpenMenus() {
  const [openMenuName, setOpenMenuName] = useState<string>('');

  const isMenuOpen = useCallback(
    (menuName: string) => {
      return openMenuName === menuName;
    },
    [openMenuName],
  );

  const openMenu = (menuName: string) => {
    setOpenMenuName(menuName);
  };

  const closeMenu = () => {
    setOpenMenuName('');
  };

  const toggleMenu = (menuName: string) => {
    setOpenMenuName(currentOpenMenuName => {
      return menuName === currentOpenMenuName ? '' : menuName;
    });
  };

  return {
    openMenu,
    closeMenu,
    toggleMenu,
    isMenuOpen,
  };
}

export default useOpenMenus;
