import { useSyncExternalStore } from 'react';

import { ZEPHR_DATA_SCRIPT_ID, PaywallData, SubscriberInfo } from '@hubcms/domain-zephr';

const defaultZephrData = {
  result: null,
  paywallConfig: null,
  subscriber: null,
  reason: 'server-render',
  hasPaywall: false,
};

const zephrData = (() => {
  if (typeof document === 'undefined') {
    return defaultZephrData;
  }

  const textContent = document.getElementById(ZEPHR_DATA_SCRIPT_ID)?.textContent;

  if (!textContent) {
    return {
      ...defaultZephrData,
      reason: 'script-not-found',
    };
  }

  const scriptContent = JSON.parse(textContent);

  type ScriptContent =
    | {
        result: {
          outcomes: Array<Record<string, unknown>>;
        };
        config: {
          rows: never;
        };
        subscriber: SubscriberInfo | null;
      }
    | {
        result: {
          outcomes: never;
        };
        config: {
          rows: Array<Record<string, unknown>>;
        };
        subscriber: SubscriberInfo | null;
      };

  function checkForPaywall(scriptContent: ScriptContent) {
    // SDK
    if (scriptContent.result && Array.isArray(scriptContent.result.outcomes)) {
      return scriptContent.result.outcomes.length > 0;
    }

    // Non-SDK
    if (scriptContent.config && Array.isArray(scriptContent.config.rows)) {
      return scriptContent.config.rows.length > 0;
    }

    return false;
  }

  const hasPaywall = checkForPaywall(scriptContent);

  return {
    result: scriptContent.result || null,
    paywallConfig: scriptContent.config || null,
    subscriber: scriptContent.subscriber || null,
    reason: scriptContent.result?.reason || null,
    hasPaywall,
  };
})();

function subscribe() {
  return () => null;
}

function getSnapshot() {
  return zephrData;
}

function getServerSnapshot() {
  return defaultZephrData;
}

export const useZephrData = (): PaywallData => {
  return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
};
